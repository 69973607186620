import React from 'react';
import { connect } from 'react-redux';
import queryString from "query-string";
import { DataTable, Pager, SearchInput, Button, Dialog } from 'tyb';
const { loadFactoryPageList, uploadFactorySearchKey, enableOrDisabledFactory, } = iceStarkData.store.get('commonAction')?.factory;
import enums from '@/assets/enum.json';
import { handSortOrder } from 'ucode-utils';
import FactoryCreateForm from './components/factoryCreateForm';
import './index.scss';
const {  setNavBar } = iceStarkData.store.get('commonAction')?.ui;


const factoryTypeFilter = [
  { text: '包材印刷工厂', value: 'PRINT' },
  { text: '成品灌装工厂', value: 'PACKAGE' },
];

@connect(
  state => {
    return {
      ...state.factory,
      permissionIds: state.users.permissionIds||[],
    
    };
  },
  {
    uploadFactorySearchKey,
    loadFactoryPageList: loadFactoryPageList.REQUEST,
    enableOrDisabledFactory: enableOrDisabledFactory.REQUEST,
    setNavBar
  }
)
class FactoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortId: null,
      dataId: '',
      showFactoryCreateForm: false,
      factoryColumns: [
        {
          key: 'id',
          dataIndex: 'name',
          title: '工厂名称/ID',
          render: (value, record) => {
            return (
              <div >
                <div>{value}</div>
                <div>{record.id}</div>
              </div>
            );
          },
        },
        {
          key: 'code',
          dataIndex: 'code',
          title: '工厂编码'
        },
        {
          key: 'address',
          dataIndex: 'address',
          title: '工厂地址',
          render: (value, record) => (
            <div className="moreline-text-ellipsis" title={`${record.provinceName || ''}${record.cityName || ''}${record.countyName || ''}${value || ''}`}> {record.provinceName || ''}{record.cityName || ''}{record.countyName || ''}{value || ''} </div>
          ),
        },
        {
          key: 'factoryType',
          dataIndex: 'factoryType',
          title: '工厂类型',
          render: (value, record) => (
            
            <div> {factoryTypeFilter.filter(v => v.value == value)[0].text} </div>
          ),
        },
        {
          key: 'companyName',
          dataIndex: 'companyName',
          title: '所属公司',
          render: (value, record) => (
            
            <div className="moreline-text-ellipsis"> {value} </div>
          ),
        },
        {
          key: 'status',
          dataIndex: 'status',
          title: '操作',
          render: (value, record) => {
            return (
              <div className="table-operate-btn" style={{ color: '#006EFF' }}>
                {this.props.permissionIds.includes('data.basicData.factory.edit') && <span onClick={() => { this.setState({ showFactoryCreateForm: true, dataId: record.id }); }}>编辑</span>}
                {this.props.permissionIds.includes('data.basicData.factory.status') && <span onClick={() => {
                  this.props.enableOrDisabledFactory({ id: record.id, status: value == 'ENABLE' ? 'DISABLE' : 'ENABLE' })
                }}>
                  {value == 'ENABLE' ? '禁用' : '启用'}
                </span>}
                {this.props.permissionIds.includes('data.basicData.factory.certificate.view') &&record.factoryType=="PRINT"&& <span onClick={() => { 
                   this.props.history.push(`/databasicData/datafactory/Certificate?${queryString.stringify({ id: record.id })}`);
                   this.props.setNavBar('证书配置' );
                  }}>证书</span>}

              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { loadFactoryPageList, factoryPageListParam } = this.props;
    loadFactoryPageList();
  }

  changePage = (value, tag) => {
    const { loadFactoryPageList, factoryPageListParam } = this.props;
    const obj = tag == 'perpage' ? { ...factoryPageListParam, page: 0, size: value || 20 } : { ...factoryPageListParam, page: value };
    loadFactoryPageList({ factoryPageListParam: { ...obj } });
  };

  tableHeadFilter = param => {
    const { list, titleContent, value, filterFun } = param;
    return (
      <DataTable.FilterableColumnHeader
        list={list}
        value={value}
        onFilter={value => {
          filterFun(value);
        }}
      >
        {titleContent}
      </DataTable.FilterableColumnHeader>
    );
  };
  handleSort = (curOrder, key) => {
    const { factoryPageListParam = {}, loadFactoryPageList } = this.props;
    const newOrder = handSortOrder(curOrder);
    loadFactoryPageList({ factoryPageListParam: { ...factoryPageListParam, page: 0, sort: `${key},${newOrder}`, }, });
    this.setState({ sortId: newOrder });
  };

  // 排序 sort
  tableHeadSort = col => {
    return (
      <DataTable.SortableColumnHeader
        order={this.state.sortId}
        sortKey={col.key}
        onSort={() => { this.handleSort(this.state.sortId, col.key); }}
      >
        {col.title}
      </DataTable.SortableColumnHeader>
    );
  };
  tableHeadInit = () => {
    return this.state.factoryColumns.map(column => {
      if (column.key === 'id') {
        return {
          ...column,
          headerRender: col => this.tableHeadSort(col),
        };
      } else if (column.key === 'factoryType') {
        return {
          ...column,
          headerRender: () => {
            const { loadFactoryPageList, factoryPageListParam } = this.props;
            return this.tableHeadFilter({
              list: factoryTypeFilter,
              titleContent: '工厂类型',
              value: factoryPageListParam[column.key] ? factoryPageListParam[column.key].split(',') : [],
              filterFun: value => {
                const newValue = value.join(',');
                loadFactoryPageList({ factoryPageListParam: { ...factoryPageListParam, [column.key]: newValue, page: 0, }, });
              },
            });
          },
        };
      } else {
        return column;
      }
    });
  };

  render() {
    const { showFactoryCreateForm, dataId } = this.state;
    const { factoryPageList = [], factoryPageListParam, factorySearchKey = '', factoryPageListLoading, uploadFactorySearchKey, loadFactoryPageList, } = this.props;
    return (
      <div className={`factory-container ${factoryPageList.length > 0 ? 'table-tr-height50' : 'table-tr-middle'}`}>

        {/* top-bar */}
        <div className="factory-topbar">
          {this.props.permissionIds.includes('data.basicData.factory.add') && <Button onClick={() => { this.setState({ showFactoryCreateForm: true, dataId: '' }); }}>新增工厂</Button>}
          <div className="factory-search-input">
            <SearchInput
              value={factorySearchKey}
              placeholder="请输入工厂名称/编码搜索"
              onChange={value => {
                uploadFactorySearchKey({ factorySearchKey: value });
              }}
              onSearch={value => { loadFactoryPageList({ factoryPageListParam: { ...factoryPageListParam, name: value, page: 0, size: 20, }, }); }}
            />
          </div>

        </div>
        {/* 列表，分页 */}
        <DataTable
          columns={this.tableHeadInit()}
          data={factoryPageList}
          rowKey="id"
          rowClassName={(record) => record.status == 'DISABLE' ? 'table-gray-row' : ''}
          loading={factoryPageListLoading}
          style={{ width: '100%', maxWidth: '100%',  }} messageRender={({ data, error }) => {
            if (error != null) { return error.msg; }
            if (data == null || data.length === 0) {
              return (
                <div className="text-weak">
                  暂无工厂{this.props.permissionIds.includes('data.basicData.factory.add') ? "，您可以点击左上角" : null}
                  {this.props.permissionIds.includes('data.basicData.factory.add') && <span style={{ color: '#006EFF' }} onClick={() => { this.setState({ showFactoryCreateForm: true, dataId: '' }); }} > 新增工厂 </span>}
                </div>
              );
            }
          }}
        />
        {factoryPageList.length > 0 &&
          <div className="page-padding20">
            <Pager
              total={factoryPageListParam.totalElements}
              current={factoryPageListParam.page}
              perPageCount={factoryPageListParam.size}
              onPageChange={value => { this.changePage(value, 'page'); }}
              onPerPageChange={value => { this.changePage(value, 'perpage'); }}
            />
          </div>}

        <Dialog
          title={dataId ? "编辑工厂" : "新增工厂"}
          visible={showFactoryCreateForm}
          onCancel={() => { this.setState({ showFactoryCreateForm: false }); }}
          hasFooter={false}
          style={{ width: "485px" }}
          className="factory-modal"
        >
          <FactoryCreateForm
            dataId={dataId}
            handleClose={() => {
              this.setState({ showFactoryCreateForm: false });
            }}
          />
        </Dialog>
      </div>
    );
  }
}

export default FactoryList;
